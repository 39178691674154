import { DeviceDto, CalculateDevicesCompleteNumberFunc } from './types';

export const calculateDevicesCompleteNumber: CalculateDevicesCompleteNumberFunc = (room) => {
  const devices = room.devices;
  const totalDevicesCount = room.acQty + room.meterQty * 2; // 每一個電表皆會綁定一個卡機，因此乘以二

  const roomDeviceDeployCount = devices.reduce(
    (acc: number, device: DeviceDto) => acc + (device.isCompleted ? 1 : 0),
    0,
  );

  return {
    isDeployedNumber: roomDeviceDeployCount,
    totalDevicesCount,
    rate: `${roomDeviceDeployCount}/${totalDevicesCount}`,
    status: room.isCompleted ? 'normal' : 'default',
  };
};
