import { DeviceType } from 'hooks/device';

export const convertDeviceName = (type: string, model: string | null): string => {
  switch (type) {
    case DeviceType.TOTAL_METER: {
      if (model === 'school_total_meter') return '學校總表';
      if (model === 'ac_total_meter') return '新設冷氣總表';
      if (model === 'pv_total_meter') return '太陽光電電表';

      return '總表';
    }
    case DeviceType.METER:
      return '教室電表';
    case DeviceType.GATEWAY:
      return '轉換器';
    case DeviceType.DONGLE:
      return '冷氣控制器';
    case DeviceType.CARD_READER:
      return '教室卡機';
    case DeviceType.PV_METER:
      return '太陽光電電表';
    case DeviceType.SCHOOL_METER:
      return '學校總表';
    case DeviceType.AIR_CONDITIONER_TOTAL_METER:
      return '新設冷氣總表';
    case DeviceType.IPC:
      return '校園EMS主機';
    case DeviceType.SWITCH:
      return '交換器';
    case DeviceType.DASHBOARD:
      return '即時看板';
    case DeviceType.AVM:
      return '儲值機';
    case DeviceType.ROUTER:
      return '4G Router';
    case DeviceType.PM25:
      return 'PM 2.5';
    case DeviceType.ROOM:
      return '教室';
    default:
      return '未知';
  }
};
