import * as React from 'react';
import { FieldProps } from 'formik';
import { TextField, ITextFieldProps, MaskedTextField } from '@fluentui/react';
import { getErrorMessage, Omit } from './utils';

export function mapFieldToTextField<T = any>({
  form,
  field,
}: FieldProps<T>): Pick<ITextFieldProps, 'value' | 'name' | 'onChange' | 'onBlur' | 'errorMessage' | 'form'> {
  return {
    ...(field as any),
    errorMessage: getErrorMessage({ form, field } as FieldProps<T>),
  };
}

export function mapFieldToMaskedTextField<T = any>({
  form,
  field,
}: FieldProps<T>): Pick<ITextFieldProps, 'value' | 'name' | 'onChange' | 'onBlur' | 'errorMessage' | 'form'> {
  return {
    ...mapFieldToTextField({ form, field } as FieldProps<T>),
    // ev hsa wrong balue for MaskedTextField
    onChange: (_, value) => form.setFieldValue(field.name, value),
  };
}

export type FormikTextFieldProps<T = any> = Omit<ITextFieldProps, 'value' | 'name' | 'onChange' | 'onBlur' | 'form'> &
  FieldProps<T>;

export function FormikTextField<T = any>({ field, form, ...props }: FormikTextFieldProps<T>) {
  const { errorMessage, ...fieldProps } = mapFieldToTextField({ field, form } as FieldProps<T>);

  return (
    <TextField
      errorMessage={errorMessage} // allow for prop overwrite
      {...props}
      {...fieldProps}
    />
  );
}

export function FormikMaskedTextField<T = any>({ field, form, ...props }: FormikTextFieldProps<T>) {
  const { errorMessage, ...fieldProps } = mapFieldToMaskedTextField({
    field,
    form,
  } as FormikTextFieldProps<T>);

  return (
    <MaskedTextField
      errorMessage={errorMessage} // allow for prop overwrite
      {...props}
      {...fieldProps}
    />
  );
}
