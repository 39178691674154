import axios from 'axios';

import { DeleteDeviceFunc, GetDropdownFunc } from './types';

export const deleteRoomDevice: DeleteDeviceFunc = async ({ deviceId, schoolId }) => {
  const res = await axios({
    method: 'DELETE',
    url: `/device/${deviceId}`,
    data: { schoolId },
  });

  return res.data;
};

// export const getAcTypeList: GetDropdownFunc = async () => {
//   const res = await axios({
//     method: 'GET',
//     url: '/device/dongle/ac/type',
//   });
//   return res.data;
// };
// export const getAcModelList: GetDropdownFunc = async () => {
//   const res = await axios({
//     method: 'GET',
//     url: '/device/dongle/ac/model',
//   });
//   return res.data;
// };
