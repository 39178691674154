import React, { FC } from 'react';
import { FieldProps } from 'formik';

import { textFieldStyles } from './styles';
import { FormikDropdownProps } from './types';
import { FormikDropdown } from 'components/formik/FormikDropdown';
import { FormikTextField } from 'components/formik/FormikTextField';
import { FormikSpinButton, FormikSpinButtonProps } from 'components/formik/FormikSpinButton';

export const TextField: FC<FieldProps> = (props) => {
  return <FormikTextField {...props} styles={textFieldStyles} />;
};
export const DropdownField: FC<FormikDropdownProps> = (props) => (
  <FormikDropdown
    {...props}
    {...(props.options?.length > 0 ? { placeholder: props.placeholder } : { placeholder: '目前無任何選項' })}
  />
);
export const SpinButtonField: FC<FormikSpinButtonProps> = (props) => <FormikSpinButton {...props} />;
