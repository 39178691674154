import axios from 'axios';

import {
  CreateSchoolFunc,
  GetSchoolListFunc,
  UpdateSchoolFunc,
  DeleteSchoolFunc,
  UpdateSchoolSurveyInfoFunc,
  UploadImageToSchoolFunc,
  DeleteImageFromSchoolFunc,
  ImportSchoolFunc,
  AssignUserToSchoolFunc,
  DownloadSchoolReportFunc,
  GetSchoolDropdownOptionsFunc,
  GetSchoolProgressFunc,
} from './types';

export const getSchoolList: GetSchoolListFunc = async (context) => {
  const [, query] = context.queryKey;

  const res = await axios({
    method: 'GET',
    url: '/school',
    params: query,
  });

  return res.data;
};

export const getSchoolDropdownOptions: GetSchoolDropdownOptionsFunc = async (context) => {
  const [, query] = context.queryKey;

  const res = await axios({
    method: 'GET',
    url: '/school/assignable',
    params: query,
  });

  return res.data;
};

export const getSchoolProgress: GetSchoolProgressFunc = async (context) => {
  const [, query] = context.queryKey;

  const res = await axios({
    method: 'GET',
    url: '/school/progress',
    params: query,
  });

  return res.data;
};

export const assignUserToSchool: AssignUserToSchoolFunc = async (payload) => {
  const res = await axios({
    method: 'PUT',
    url: '/school/assign/supervisor',
    data: payload,
  });

  return res.data;
};

export const createSchool: CreateSchoolFunc = async (payload) => {
  const res = await axios({
    method: 'POST',
    url: '/school',
    data: payload,
  });

  return res.data;
};

export const updateSchool: UpdateSchoolFunc = async ({ schoolId, ...payload }) => {
  const res = await axios({
    method: 'PATCH',
    url: `/school/${schoolId}`,
    data: payload,
  });

  return res.data;
};

export const deleteSchoolAsync: DeleteSchoolFunc = async ({ schoolId }) => {
  const res = await axios({
    method: 'DELETE',
    url: `/school/${schoolId}`,
  });

  return res.data;
};

export const deleteSchool: DeleteSchoolFunc = async ({ schoolId }) => {
  if (typeof schoolId === 'number') {
    return await deleteSchoolAsync({ schoolId });
  }

  if (Array.isArray(schoolId)) {
    // 群刪
    await Promise.all(schoolId.map((schoolId) => deleteSchoolAsync({ schoolId })));
    return { message: '群刪學校成功', data: {} };
  }

  throw new Error('刪除學校失敗，請稍後再試');
};

export const updateSchoolSurveyInfo: UpdateSchoolSurveyInfoFunc = async ({ id, ...payload }) => {
  const res = await axios({
    method: 'POST',
    url: `/school/quantity/${id}`,
    data: payload,
  });

  return res.data;
};

export const uploadImageToSchool: UploadImageToSchoolFunc = async ({ schoolId, file }) => {
  const formData = new FormData();
  const filename = file.name.replace(/.(jpg|jpeg|png)$/, '');
  formData.append('plans', file);
  formData.append('name', filename);

  const res = await axios({
    method: 'POST',
    url: `/school/plans/${schoolId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  return res.data;
};

export const deleteImageFromSchool: DeleteImageFromSchoolFunc = async (payload) => {
  const res = await axios({
    method: 'DELETE',
    url: '/school/plans',
    data: payload,
  });

  return res.data;
};

export const downloadSchoolTemplate = async (): Promise<Blob> => {
  const res = await axios({
    method: 'GET',
    url: '/school/template',
    responseType: 'blob', // important
  });

  return res.data;
};

export const importSchool: ImportSchoolFunc = async ({ file, vendors, supervisors, ...params }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('vendors', JSON.stringify(vendors));
  formData.append('supervisors', JSON.stringify(supervisors));

  const res = await axios({
    method: 'POST',
    url: '/school/import',
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  return res.data;
};

export const downloadSchoolReport: DownloadSchoolReportFunc = async ({ schoolId }) => {
  const res = await axios({
    method: 'GET',
    url: '/school/export/report/install/docs',
    responseType: 'blob',
    params: { schoolId },
  });
  const contentDispositionHeader = res.headers['content-disposition'];
  const filename = decodeURI(contentDispositionHeader.split("UTF-8''")[1]);

  return { filename, attachment: res.data };
};

export const DownloadDeviceTable: DownloadSchoolReportFunc = async ({ schoolId }) => {
  const res = await axios({
    method: 'GET',
    url: '/school/export/report/slaveId/xlsx',
    responseType: 'blob',
    params: { id: schoolId },
  });
  const contentDispositionHeader = res.headers['content-disposition'];
  const filename = decodeURI(contentDispositionHeader.split("UTF-8''")[1]);

  return { filename, attachment: res.data };
};
