import React, { useMemo, ComponentType, useEffect, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';

import { AuthContextState } from 'context/interceptorProvider.type';
import createCtx from 'utils/createCtx';
import { USER } from 'constants/query';

import { logoutStatusCodes } from '../constants/logoutStatusCodes';

export const [useClientAuth, CtxProvider] = createCtx<AuthContextState>();

const InterceptorProvider: ComponentType = ({ children }) => {
  const queryClient = useQueryClient();

  const axiosResponseInterceptors = useCallback(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (logoutStatusCodes.includes(error.response?.data.code)) {
          localStorage.removeItem('token');
          queryClient.setQueryData(USER, undefined);
        }

        // 當 API request responseType 設為 blob 後，response 成功時為 blob 沒錯，但若是錯誤卻會是 JSON
        // 因此透過此 condition 將錯誤內容轉換成 JSON
        // FYI: https://github.com/axios/axios/issues/815
        if (
          error.request.responseType === 'blob' &&
          error.response?.data instanceof Blob &&
          error.response?.data.type &&
          error.response?.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
              if (error.response?.data) {
                error.response.data = JSON.parse(reader.result as string);
                resolve(Promise.reject(error));
              }
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response?.data);
          });
        }

        return Promise.reject(error);
      },
    );
  }, []);

  useEffect(() => {
    axiosResponseInterceptors();
  }, []);

  const InterceptorsDataValue = useMemo(() => ({}), []);

  return (
    <CtxProvider
      value={{
        ...InterceptorsDataValue,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export default InterceptorProvider;
