import { UseQueryResult, QueryFunctionContext, UseMutationResult, MutationFunction } from 'react-query';

import { TownDto, CityDto } from 'hooks/city';
import { SuccessDto, AxiosErrorDto, GetResponseDto } from 'types/axios';
import { SCHOOL_DROPDOWN_OPTIONS, SCHOOL_LIST, SCHOOL_PROGRESS } from 'constants/query';
import { RoleNumberEnum } from 'hooks/auth';
import { DeviceType } from 'hooks/device';
import { TotalMeterEnum } from 'hooks/totalMeter';

export enum SchoolState {
  WAIT_ASSIGN = 'wait_assign', // 未分配管理者：若未分管理者，為此狀態
  WAIT_SURVEY = 'wait_survey', // 待場勘：若教室資訊 / Gateway資訊 / 總表資訊 , 未填寫時，為此狀態
  FILING = 'filing', // 資料建檔中：若建檔數不等於需安裝總數，或是每一筆建檔資料，有required欄位未填寫，為此狀態
  WAIT_INSTALL = 'wait_install', // 待安裝：若工班尚未開始進行安裝，為此狀態
  INSTALLING = 'installing', // 安裝中：若安裝數量不為0時，為此狀態
  INSTALLED = 'installed', // 待驗收：若安裝數量與建檔資料相同時，為此狀態
  CHECKED = 'checked', // 已驗收：若統包商與工班 APP 點選驗收
}

export enum SchoolType {
  ELEMENTARY = 'elementary',
  JUNIOR = 'junior',
  SENIOR = 'senior',
  VOCATIONAL = 'vocational',
  SIX_YEAR = 'sixyear',
  NINE_YEAR = 'nineyear',
}

export type SchoolDto = SchoolBasicInfoDto & SchoolVendorDto & SchoolAssignmentDto;

export type SchoolBasicInfoDto = {
  id: number;
  name?: string;
  serialNumber?: string;
  city: Omit<CityDto, 'towns'>;
  town: TownDto;
  address?: string;
  type: SchoolType;
  state: SchoolState;
  progress: number;
  assetsQtys: assetsQty[];
  vendors: VendorDto[];
  ips: { id: number; ip: string }[];
  plans: { id: number; url: string }[];
  contact: string;
  contactPhone: string;
  supervisors: {
    role: { id: number; key: string; name: string } | null;
    superior: { id: number; name: string; account: string } | null;
  }[];
};

export type SchoolQuantityDto = {
  schoolMeterQty?: number;
  acTotalMeterQty?: number;
  pvMeterQty?: number;
  roomQty?: number;
  meterQty?: number;
  acQty?: number;
  gatewayQty?: number;
};

export type assetsQty = {
  id: number;
  type: string;
  model: string | null;
  demandQty: number;
  fileQty: number;
  installQty: number;
};

export type SchoolAssignmentDto = {
  contractorId: {
    id: number;
    name: string;
  } | null;
  managerId: {
    id: number;
    name: string;
  } | null;
  workerId: {
    id: number;
    name: string;
  } | null;
};

export type VendorDto = {
  id: number;
  type: string;
  model: null | string;
  vendor: {
    id: number;
    name: string;
    key: string;
    dongle: boolean;
    meter: boolean;
    gateway: boolean;
  };
};

export type RoleQuery = {
  contractorId: number;
  managerId: number;
  vendorId: number;
  workerId: number;
};

export type SchoolVendorDto = {
  dongleVendorId?: number;
  gatewayVendorId?: number;
  meterVendorId?: number;
};

export type UseSchoolQuery = {
  schoolList?: GetSchoolListQuery;
  schoolDropdownOptions?: GetSchoolDropdownOptionsQuery;
  schoolProgress?: GetSchoolProgressQuery;
};

// get school list
export type GetSchoolListQuery = { id?: number; limit?: number; superiorId?: number };
export type GetSchoolFuncContext = QueryFunctionContext<[typeof SCHOOL_LIST, UseSchoolQuery['schoolList']]>;
export type GetSchoolListFunc = (context: GetSchoolFuncContext) => Promise<GetResponseDto<SchoolDto[]>>;

// get school dropdown options
/* 後端會根據 query 篩選回傳尚未被指派的學校或是指派在該人員底下的學校列表 */
export type GetSchoolDropdownOptionsQuery =
  | { cityCode: number }
  | { superiorId?: number; staffId?: number } // 編輯人員時，提供 staffId
  | { superiorId?: number; roleId: RoleNumberEnum }; // 新增人員時，需指定 roleId
export type SchoolDropdownOptionsDto = {
  id: number;
  name: string;
  city: {
    code: number;
    name: string;
    enName: string;
  };
  address: string;
};
export type GetSchoolDropdownOptionsContext = QueryFunctionContext<
  [typeof SCHOOL_DROPDOWN_OPTIONS, UseSchoolQuery['schoolDropdownOptions']]
>;
export type GetSchoolDropdownOptionsFunc = (
  context: GetSchoolDropdownOptionsContext,
) => Promise<GetResponseDto<SchoolDropdownOptionsDto[]>>;

// get school progress
export type SchoolProgressDto = {
  name: string;
  assetsQtys: {
    id: number;
    type: DeviceType;
    model: TotalMeterEnum;
    demandQty: number;
    fileQty: number;
    installQty: number;
  }[];
};
export type GetSchoolProgressQuery = { id?: number };
export type GetSchoolProgressContext = QueryFunctionContext<[typeof SCHOOL_PROGRESS, UseSchoolQuery['schoolProgress']]>;
export type GetSchoolProgressFunc = (context: GetSchoolProgressContext) => Promise<GetResponseDto<SchoolProgressDto>>;

// create school
export type CreateSchoolPayload = {
  name: string;
  serialNumber: string;
  address: string;
  type: SchoolType;
  cityCode: number;
  townId: number;
  supervisors: { roleId: RoleNumberEnum; supervisorId?: number }[];
  vendors: { type: string; vendorId?: number }[];
};
export type CreateSchoolFunc = (payload: CreateSchoolPayload) => Promise<SuccessDto<SchoolDto>>;

// delete school
export type DeleteSchoolPayload = { schoolId: number | number[] };
export type DeleteSchoolFunc = (payload: DeleteSchoolPayload) => Promise<SuccessDto<object>>;

// assign
export type AssignUserToSchoolPayload = {
  userId: number;
  roleId: number;
  schoolIds?: number[];
  removeSchoolIds?: number[];
};
export type AssignUserToSchoolFunc = MutationFunction<SuccessDto<object>, AssignUserToSchoolPayload>;

// update school info
export type UpdateSchoolPayload = CreateSchoolPayload & { schoolId: number };
export type UpdateSchoolFunc = (payload: UpdateSchoolPayload) => Promise<SuccessDto<SchoolDto>>;

// update school survey quantity
// 場勘前，人員須填寫每間學校所需安裝個設備教室的總數
export type UpdateSchoolQuantityPayload = {
  id: number;
  schoolMeterQty?: number;
  acTotalMeterQty?: number;
  pvMeterQty?: number;
  roomQty?: number;
  meterQty?: number;
  acQty?: number;
  gatewayQty?: number;
};
export type UpdateSchoolSurveyInfoFunc = MutationFunction<SuccessDto<object>, UpdateSchoolQuantityPayload>;

// upload image
export type UploadImageToSchoolPayload = { schoolId: number; file: File };
export type UploadImageToSchoolFunc = (payload: UploadImageToSchoolPayload) => Promise<SuccessDto<object>>;

// delete image
export type DeleteImageFromSchoolPayload = { ids: number[]; schoolId?: number };
export type DeleteImageFromSchoolFunc = (payload: DeleteImageFromSchoolPayload) => Promise<SuccessDto<object>>;

// batch import school info
export type ImportSchoolPayload = {
  vendors: { type: string; vendorId?: number }[];
  supervisors: { roleId: RoleNumberEnum; supervisorId?: number }[];
  file: Blob;
};
export type ImportSchoolFunc = (payload: ImportSchoolPayload) => Promise<SuccessDto<object>>;

// download school report
// 所有設備安裝完成後，可透過此 API 獲得結案報告
export type DownloadSchoolReportPayload = { schoolId: number };
export type DownloadSchoolReportResDto = { filename: string; attachment: Blob };
export type DownloadSchoolReportFunc = (payload: DownloadSchoolReportPayload) => Promise<DownloadSchoolReportResDto>;

// download device table
// 下載 excel，欄位是每一個設備的 slaveId, serial number, 所屬 gateway 以及對應的教室的 table，方便現場人員查對
export type DownloadDeviceTablePayload = { schoolId: number };
export type DownloadDeviceTableResDto = { filename: string; attachment: Blob };
export type DownloadDeviceTableFunc = (payload: DownloadDeviceTablePayload) => Promise<DownloadDeviceTableResDto>;

export type UseSchoolRto = {
  schoolListQuery: UseQueryResult<GetResponseDto<SchoolDto[]>, AxiosErrorDto>;
  schoolDropdownOptionsQuery: UseQueryResult<GetResponseDto<SchoolDropdownOptionsDto[]>, AxiosErrorDto>;
  schoolProgressQuery: UseQueryResult<GetResponseDto<SchoolProgressDto>, AxiosErrorDto>;

  userAssignSchoolMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, AssignUserToSchoolPayload>;
  schoolCreateMutation: UseMutationResult<SuccessDto<SchoolDto>, AxiosErrorDto, CreateSchoolPayload>;
  schoolUpdateMutation: UseMutationResult<SuccessDto<SchoolDto>, AxiosErrorDto, UpdateSchoolPayload>;
  schoolDeleteMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, DeleteSchoolPayload>;
  schoolSurveyInfoUpdateMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, UpdateSchoolQuantityPayload>;
  schoolUploadImageMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, UploadImageToSchoolPayload>;
  schoolDeleteImageMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, DeleteImageFromSchoolPayload>;
  schoolTemplateDownloadMutation: UseMutationResult<Blob, AxiosErrorDto, void>;
  schoolImportMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, ImportSchoolPayload>;
  schoolDownloadReportMutation: UseMutationResult<
    DownloadSchoolReportResDto,
    AxiosErrorDto,
    DownloadSchoolReportPayload
  >;
  schoolDownloadDeviceTableMutation: UseMutationResult<
    DownloadDeviceTableResDto,
    AxiosErrorDto,
    DownloadDeviceTablePayload
  >;
};
