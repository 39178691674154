export const USER = 'user';

export const CONTRACTOR_LIST = 'contractorList';
export const MANAGER_LIST = 'managerList';
export const WORKER_LIST = 'workerList';

export const STAFF_LIST = 'staffList';
export const STAFF = 'staff';

export const SCHOOL_LIST = 'schoolList';
export const SCHOOL_DROPDOWN_OPTIONS = 'schoolDropdownOptions';
export const SCHOOL_PROGRESS = 'school_progress';

export const VENDOR = 'vendor';

export const GATEWAY = 'gateway';
export const GATEWAY_DROPDOWN_OPTIONS = 'gatewayDropdownOptions';

export const BUILDING_LIST = 'buildingList';

export const ROOM_LIST = 'roomList';

export const TOTAL_METER_LIST = 'totalMeterList';

export const INVENTORY = 'inventory';
export const INVENTORY_TOTAL = 'inventoryTotal';
export const INVENTORY_WITH_VENDOR = 'inventoryWithVendor';
export const DONGLE_SLAVE_ID_REQUIRED_COUNT = 'dongleSlaveIdRequiredCount';

export const CITY = 'city';
export const CITY_DEPLOY_PROGRESS = 'cityDeployProgress';

export const GENERAL_DEVICE = 'generalDevice';

export const WORKER_MONTHLY_DEPLOY_IN_CITY = 'workerMonthlyDeployInCity';
export const CITY_MONTHLY_DEPLOY = 'cityMonthlyDeploy';

export const QUICK_INSTALL_LIST = 'quick_install_list';
export const QUICK_INSTALL_DEVICE = 'quick_install_device';

export const AC_MODEL_DROPDOWN = 'ac_model_dropdown';
export const AC_TYPE_DROPDOWN = 'ac_type_dropdown';
