import React from 'react';
import { FieldProps } from 'formik';
import { createFakeEvent, getErrorMessage, Omit } from './utils';
import { Dropdown, IDropdownProps } from '@fluentui/react';
import { errorPrimary, neutralPrimary } from 'oring-ui/lib/styles/variables';

// 將 formik-office-ui-fabric-react 的 FormikDropdown 抽取出來改寫中

export function mapFieldToDropdown<T = any>({
  form,
  field,
}: FieldProps<T>): Pick<
  IDropdownProps,
  'selectedKey' | 'selectedKeys' | 'onDismiss' | 'onChange' | 'errorMessage' | 'onBlur'
> {
  const multiSelect = Array.isArray(field.value);

  const shared = {
    errorMessage: getErrorMessage<T>({ field, form } as FieldProps<T>),
    onDismiss: () => {
      !field.value && field.onBlur(createFakeEvent(field));
    },
    onBlur: () => {
      field.onBlur(createFakeEvent(field));
    },
  };

  return multiSelect
    ? {
        ...shared,
        selectedKeys: field.value as any,
        onChange: (_, option) => {
          const value = field.value as any;

          if (option?.selected) {
            form.setFieldValue(field.name, [...value, option?.key]);
          } else {
            const idx = (field.value as any).indexOf(option?.key);

            if (idx !== -1) {
              form.setFieldValue(field.name, [...value.slice(0, idx), ...value.slice(idx + 1)]);
            }
          }
        },
      }
    : {
        ...shared,
        selectedKey: field.value as any,
        onChange: (_, option) => {
          form.setFieldValue(field.name, option?.key);
        },
      };
}

export type FormikDropdownProps<T> = Omit<IDropdownProps, 'selectedKey'> & FieldProps<T>;

export function FormikDropdown<T = any>({
  field,
  form,
  ...props
}: FormikDropdownProps<T>): React.ReactElement<FormikDropdownProps<T>> {
  const { errorMessage, onDismiss, onBlur, ...fieldProps } = mapFieldToDropdown({
    field,
    form,
  } as FieldProps<T>);

  return (
    <Dropdown
      errorMessage={errorMessage}
      {...props}
      onDismiss={onDismiss}
      {...fieldProps}
      onBlur={onBlur}
      styles={{
        ...props.styles,
        title: {
          borderColor: errorMessage ? errorPrimary : neutralPrimary,
        },
        errorMessage: {
          color: errorPrimary,
          fontSize: '0.75rem',
        },
      }}
    />
  );
}

// return multiSelect
//   ? {
//       ...shared,
//       selectedKeys: field.value as string[] | number[],
//       onChange: (_, option) => {
//         const value = field.value as (string | number)[];

//         if (option!.selected) {
//           form.setFieldValue(field.name, [...value, option!.key]);
//         } else {
//           const idx = (field.value as (string | number)[]).indexOf(option!.key);

//           if (idx !== -1) {
//             form.setFieldValue(field.name, [...value.slice(0, idx), ...value.slice(idx + 1)]);
//           }
//         }
//       },
//     }
//   : {
//       ...shared,
//       selectedKey: field.value as string | number,
//       onChange: (_, option) => {
//         form.setFieldValue(field.name, option!.key);
//       },
//     };
