import React, { FC } from 'react';

import { PublicLayoutWrapper, CenterBlock, TitleBlock } from './styles';

export const PublicLayout: FC = ({ children }) => (
  <PublicLayoutWrapper>
    <div className="bg">
      <div className="bg-top" />
      <div className="bg-bottom" />
    </div>
    <CenterBlock className="center-block">
      <TitleBlock>
        <h1 className="title">班班冷氣安裝</h1>
        <p className="version">v1.4.15</p>
      </TitleBlock>
      {children}
    </CenterBlock>
  </PublicLayoutWrapper>
);
