import React, { FC } from 'react';
import { useDarkMode } from 'oring-ui/lib/hooks';
import { CSSReset } from 'oring-ui/lib/components';
import { ORingThemeProvider } from 'oring-ui/lib/provider';
import { QueryClientProvider } from 'react-query';
import { ToastProvider } from 'react-toast-notifications';
import { ReactQueryDevtools } from 'react-query/devtools';

import Router from 'routes/router';
import { GlobalToastStyles, Normalize } from 'styles';
import 'config/fluentui';
import { queryClient } from 'config/reactQuery';

const App: FC = () => {
  const { theme, mountedComponent } = useDarkMode();

  if (!mountedComponent) {
    return <></>;
  }

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ORingThemeProvider mode={theme}>
          <CSSReset />
          <GlobalToastStyles />
          <Normalize />
          <ToastProvider autoDismiss autoDismissTimeout={5000}>
            <Router />
          </ToastProvider>
        </ORingThemeProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
