import { UseQueryResult, QueryFunctionContext, UseMutationResult } from 'react-query';

import { SuccessDto, AxiosErrorDto, GetResponseDto } from 'types/axios';
import { TOTAL_METER_LIST } from 'constants/query';
import { ImageDto } from 'types/api';

export enum TotalMeterEnum {
  SCHOOL_METER = 'school_total_meter',
  AC_TOTAL_METER = 'ac_total_meter',
  PV_METER = 'pv_total_meter',
}

export enum TotalMeterPowerSystemEnum {
  '3P4W' = '3P4W',
  '3P3W' = '3P3W',
  '1P3W' = '1P3W',
}

export type TotalMeterDto = {
  id: number;
  voltageHigh: number;
  voltageLow: number;
  CT: number;
  nature: boolean;
  hasInsider: boolean;
  ctType: number;
  powerSystem: TotalMeterPowerSystemEnum;
  name: string;
  location: string;
  place: string;
  type: string;
  model: TotalMeterEnum;
  gateway: { id: number; serialNumber: string } | null;
  serialPort: number | null;
  buildings: BuildingDto[];
  vendor: { id: number; name: string } | null;
  images: ImageDto[];
  isCompleted: boolean;
  serialNumber: string | null;
  slaveId: number | null;
};

export type BuildingDto = { id: number; name: string };

export type CreateOrUpdateTotalMeterPayload = {
  name: string;
  gatewayId?: number;
  serialPort?: number;
  location: string;
  buildingId?: number[];
  voltageHigh?: number;
  voltageLow?: number;
  CT?: number;
  nature?: boolean;
  hasInsider?: boolean;
  schoolId: number;
};

// get
export type GetTotalMeterListQuery = { schoolId?: number; gatewayId?: number; id?: number };
export type GetTotalMeterFuncContext = QueryFunctionContext<[typeof TOTAL_METER_LIST, GetTotalMeterListQuery]>;
export type GetTotalMeterListFunc = (context: GetTotalMeterFuncContext) => Promise<GetResponseDto<TotalMeterDto[]>>;

// create
export type CreateTotalMeterFunc = (payload: CreateOrUpdateTotalMeterPayload) => Promise<SuccessDto<TotalMeterDto>>;

// update
export type UpdateTotalMeterPayload = CreateOrUpdateTotalMeterPayload & { totalMeterId: number };
export type UpdateTotalMeterFunc = (payload: UpdateTotalMeterPayload) => Promise<SuccessDto<TotalMeterDto>>;

// delete
export type DeleteTotalMeterPayload = { totalMeterId: number; schoolId: number };
export type DeleteTotalMeterFunc = (payload: DeleteTotalMeterPayload) => Promise<SuccessDto<object>>;

export type UseTotalMeterRto = {
  totalMeterListQuery: UseQueryResult<GetResponseDto<TotalMeterDto[]>, AxiosErrorDto>;
  totalMeterCreateMutation: UseMutationResult<
    SuccessDto<TotalMeterDto>,
    AxiosErrorDto,
    CreateOrUpdateTotalMeterPayload
  >;
  totalMeterUpdateMutation: UseMutationResult<SuccessDto<TotalMeterDto>, AxiosErrorDto, UpdateTotalMeterPayload>;
  totalMeterDeleteMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, DeleteTotalMeterPayload>;
};
