import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QUICK_INSTALL_LIST } from 'constants/query';
import { useToast } from 'hooks/toast';
import { SuccessDto } from 'types/axios';

import { UseDeviceRto } from './types';
import { deleteRoomDevice } from './api';

export const useDevice = (): UseDeviceRto => {
  const { pushApiResErrorToast, pushApiResSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const handleMutationSuccess = (success: SuccessDto<unknown>) => {
    queryClient.invalidateQueries(QUICK_INSTALL_LIST);
    pushApiResSuccessToast(success);
  };

  const deviceDeleteMutation: UseDeviceRto['deviceDeleteMutation'] = useMutation(deleteRoomDevice, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  return {
    deviceDeleteMutation,
  };
};
